@font-face {
  font-family: Calicanto;
  src: url("Calicanto-MediumItalic.dd19d61e.eot");
  src: local(Calicanto Medium Italic), local(Calicanto-MediumItalic), url("Calicanto-MediumItalic.dd19d61e.eot#iefix") format("embedded-opentype"), url("Calicanto-MediumItalic.9f33926f.woff2") format("woff2"), url("Calicanto-MediumItalic.06a834f6.woff") format("woff"), url("Calicanto-MediumItalic.644b293a.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-Regular.6ac4d8b5.eot");
  src: local(Calicanto Regular), local(Calicanto-Regular), url("Calicanto-Regular.6ac4d8b5.eot#iefix") format("embedded-opentype"), url("Calicanto-Regular.91110c75.woff2") format("woff2"), url("Calicanto-Regular.c483a74a.woff") format("woff"), url("Calicanto-Regular.e962c8a4.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-SemiBoldItalic.90d5ecbc.eot");
  src: local(Calicanto SemiBold Italic), local(Calicanto-SemiBoldItalic), url("Calicanto-SemiBoldItalic.90d5ecbc.eot#iefix") format("embedded-opentype"), url("Calicanto-SemiBoldItalic.43c0c32f.woff2") format("woff2"), url("Calicanto-SemiBoldItalic.ac8d3cdb.woff") format("woff"), url("Calicanto-SemiBoldItalic.d5d1930a.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-ExtraBoldItalic.32cd40c7.eot");
  src: local(Calicanto ExtraBold Italic), local(Calicanto-ExtraBoldItalic), url("Calicanto-ExtraBoldItalic.32cd40c7.eot#iefix") format("embedded-opentype"), url("Calicanto-ExtraBoldItalic.6fb9640d.woff2") format("woff2"), url("Calicanto-ExtraBoldItalic.26864808.woff") format("woff"), url("Calicanto-ExtraBoldItalic.25e58518.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-Medium.962bafa7.eot");
  src: local(Calicanto Medium), local(Calicanto-Medium), url("Calicanto-Medium.962bafa7.eot#iefix") format("embedded-opentype"), url("Calicanto-Medium.cce99fdf.woff2") format("woff2"), url("Calicanto-Medium.86026628.woff") format("woff"), url("Calicanto-Medium.bddaa2fb.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-LightItalic.f0fb9dad.eot");
  src: local(Calicanto Light Italic), local(Calicanto-LightItalic), url("Calicanto-LightItalic.f0fb9dad.eot#iefix") format("embedded-opentype"), url("Calicanto-LightItalic.30cb63dd.woff2") format("woff2"), url("Calicanto-LightItalic.539bc515.woff") format("woff"), url("Calicanto-LightItalic.eb796b10.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-Light.da14c233.eot");
  src: local(Calicanto Light), local(Calicanto-Light), url("Calicanto-Light.da14c233.eot#iefix") format("embedded-opentype"), url("Calicanto-Light.6bb02f45.woff2") format("woff2"), url("Calicanto-Light.f399a1de.woff") format("woff"), url("Calicanto-Light.5b638f43.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-RegularItalic.63b41166.eot");
  src: local(Calicanto Regular Italic), local(Calicanto-RegularItalic), url("Calicanto-RegularItalic.63b41166.eot#iefix") format("embedded-opentype"), url("Calicanto-RegularItalic.c933a3ee.woff2") format("woff2"), url("Calicanto-RegularItalic.9705e759.woff") format("woff"), url("Calicanto-RegularItalic.9eaf49b5.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-Bold.020a97cd.eot");
  src: local(Calicanto Bold), local(Calicanto-Bold), url("Calicanto-Bold.020a97cd.eot#iefix") format("embedded-opentype"), url("Calicanto-Bold.1bfdad8a.woff2") format("woff2"), url("Calicanto-Bold.b488b6d0.woff") format("woff"), url("Calicanto-Bold.507352fe.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-BoldItalic.b7e3e46d.eot");
  src: local(Calicanto Bold Italic), local(Calicanto-BoldItalic), url("Calicanto-BoldItalic.b7e3e46d.eot#iefix") format("embedded-opentype"), url("Calicanto-BoldItalic.c1125fd2.woff2") format("woff2"), url("Calicanto-BoldItalic.4343bced.woff") format("woff"), url("Calicanto-BoldItalic.6dd859b4.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-ExtraBold.e14e37ed.eot");
  src: local(Calicanto ExtraBold), local(Calicanto-ExtraBold), url("Calicanto-ExtraBold.e14e37ed.eot#iefix") format("embedded-opentype"), url("Calicanto-ExtraBold.c27661d9.woff2") format("woff2"), url("Calicanto-ExtraBold.5b85524d.woff") format("woff"), url("Calicanto-ExtraBold.0456c209.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Calicanto;
  src: url("Calicanto-SemiBold.a0be5226.eot");
  src: local(Calicanto SemiBold), local(Calicanto-SemiBold), url("Calicanto-SemiBold.a0be5226.eot#iefix") format("embedded-opentype"), url("Calicanto-SemiBold.e47f5970.woff2") format("woff2"), url("Calicanto-SemiBold.7ae5fd6d.woff") format("woff"), url("Calicanto-SemiBold.789b9bd0.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-4 {
  bottom: 1rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.m-auto {
  margin: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.w-60 {
  width: 15rem;
}

.justify-center {
  justify-content: center;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.bg-\[\#0000005c\] {
  background-color: #0000005c;
}

.bg-\[\#3182ce\] {
  --tw-bg-opacity: 1;
  background-color: rgb(49 130 206 / var(--tw-bg-opacity));
}

.bg-\[\#385898\] {
  --tw-bg-opacity: 1;
  background-color: rgb(56 88 152 / var(--tw-bg-opacity));
}

.p-8 {
  padding: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[\#0000005c\]\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-\[\#3182ce\]\/20 {
  --tw-shadow-color: #3182ce33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-\[\#385898\]\/20 {
  --tw-shadow-color: #38589833;
  --tw-shadow: var(--tw-shadow-colored);
}

@font-face {
  font-family: Forte;
  src: url("forte.2faf1991.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Bulletto Killa;
  src: url("Bulletto_Killa.f129417a.ttf") format("truetype");
  font-display: swap;
}

html, body, main, section {
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  scrollbar-width: none;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-size: 14px;
}

@media (width >= 768px) {
  html {
    font-size: 12px;
  }
}

@media (width >= 1280px) {
  html {
    font-size: 16px;
  }
}

@media (width >= 1536px) {
  html {
    font-size: 18px;
  }
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(175 189 108 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

h1 {
  font-family: Forte, Arial, sans-serif;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

h2 {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-family: Forte, Arial, sans-serif;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

header {
  z-index: 10;
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #fffb;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 6rem;
  max-height: 6rem;
  padding: 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

main {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
  --tw-scroll-snap-strictness: mandatory;
  scrollbar-width: none;
  overflow-y: scroll;
}

section, header {
  -webkit-user-select: none;
  user-select: none;
}

section {
  scroll-snap-align: start;
  text-align: center;
  width: 100%;
  padding-top: 6rem;
}

section > * {
  opacity: 0;
  animation: 1s linear forwards smooth-reveal;
}

section > :first-child {
  animation-delay: 0s;
}

section > :nth-child(2) {
  animation-delay: .25s;
}

section > :nth-child(3) {
  animation-delay: .5s;
}

section > :nth-child(4) {
  animation-delay: .75s;
}

section > :nth-child(5) {
  animation-delay: 1s;
}

section > :nth-child(6) {
  animation-delay: 1.25s;
}

section > :nth-child(7) {
  animation-delay: 1.5s;
}

section > :nth-child(8) {
  animation-delay: 1.75s;
}

section > :nth-child(9) {
  animation-delay: 2s;
}

section > :nth-child(10) {
  animation-delay: 2.25s;
}

section > :nth-child(11) {
  animation-delay: 2.5s;
}

section > :nth-child(12) {
  animation-delay: 2.75s;
}

section > :nth-child(13) {
  animation-delay: 3s;
}

section > :nth-child(14) {
  animation-delay: 3.25s;
}

section > :nth-child(15) {
  animation-delay: 3.5s;
}

section > :nth-child(16) {
  animation-delay: 3.75s;
}

section > :nth-child(17) {
  animation-delay: 4s;
}

section > :nth-child(18) {
  animation-delay: 4.25s;
}

section > :nth-child(19) {
  animation-delay: 4.5s;
}

section > :nth-child(20) {
  animation-delay: 4.75s;
}

.button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  justify-content: center;
  width: 20rem;
  padding: .5rem;
  display: flex;
}

.button img {
  filter: invert();
  width: 1.25rem;
  margin-right: .5rem;
}

.chevron-down {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: .75rem;
  left: 0;
  right: 0;
}

.chevron-down img {
  cursor: pointer;
  width: 2.75rem;
  padding: 1rem;
}

.splide__track {
  border-radius: .25rem;
}

.splide__slide img {
  width: 100%;
  height: auto;
}

.splide__slide {
  border-radius: .25rem;
  position: relative;
}

.splide__slide div {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #0009;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  width: 6px !important;
  height: 6px !important;
}

.oncobulle-logo {
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
}

.oncobulle-logo img {
  width: auto;
  height: 9rem;
}

.prices-button {
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  width: 100%;
  margin: auto auto 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Calicanto, serif;
  display: grid;
}

@media (width >= 1024px) {
  .prices-button {
    width: 66.6667%;
    margin-bottom: 2.5rem;
  }
}

.prices-button span {
  -webkit-user-select: none;
  user-select: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(175 189 108 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(175 189 108 / var(--tw-text-opacity));
  cursor: pointer;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 3.5rem;
  padding: .25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.prices-button span:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(175 189 108 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 768px) {
  .prices-button span {
    padding: .5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.download-button {
  --tw-bg-opacity: 1;
  background-color: rgb(175 189 108 / var(--tw-bg-opacity));
  --tw-shadow-color: #afbd6c33;
  --tw-shadow: var(--tw-shadow-colored);
  margin: 3rem auto auto;
}

.shadow {
  z-index: 30;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: .7;
  animation: .25s forwards bounced-reveal;
  position: fixed;
  inset: 0;
}

.prices-popup {
  z-index: 50;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .25rem;
  flex-direction: column;
  align-items: center;
  font-family: Calicanto, serif;
  display: flex;
  position: fixed;
  inset: 0;
}

@media (width >= 768px) {
  .prices-popup {
    --tw-border-opacity: 1;
    border-width: 8px;
    border-color: rgb(175 189 108 / var(--tw-border-opacity));
  }
}

.prices-popup {
  margin: 0;
}

@media (width >= 640px) {
  .prices-popup {
    margin: 2.5rem;
  }
}

@media (width >= 768px) {
  .prices-popup {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

@media (width >= 1024px) {
  .prices-popup {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

.prices-popup {
  --tw-shadow-color: #00000050;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  animation: .25s forwards bounced-reveal;
}

.prices-popup .title {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-family: Forte, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  display: block;
}

.prices-popup img {
  width: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.prices-popup .care {
  text-overflow: ellipsis;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(175 189 108 / var(--tw-border-opacity));
  column-gap: .5rem;
  width: 90%;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: flex;
  overflow: hidden;
}

@media (width >= 1024px) {
  .prices-popup .care {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.prices-popup .care .left {
  text-overflow: ellipsis;
  text-align: left;
  width: 75%;
}

.prices-popup .care .name {
  display: block;
}

.prices-popup .care .description {
  font-size: .875rem;
  font-style: italic;
  line-height: 1.25rem;
  display: block;
}

.prices-popup .care .right {
  justify-content: flex-end;
  column-gap: .5rem;
  width: 25%;
  display: flex;
}

.prices-popup .care .right .duration, .prices-popup .care .right .price {
  width: auto;
  display: block;
}

.contact-button {
  margin: 1rem auto;
}

.contact-address {
  -webkit-user-select: all;
  user-select: all;
  padding: 1rem;
}

.animate-smooth-bounce {
  animation: 2s linear infinite smooth-bounce;
}

@keyframes smooth-bounce {
  0% {
    opacity: 0;
    transform: translate3d(0, -5px, 0);
  }

  50% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes smooth-reveal {
  0% {
    transform: translate3d(0, -10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounced-reveal {
  0% {
    opacity: 0;
    margin: 100%;
  }

  70% {
    opacity: .25;
  }

  100% {
    opacity: 1;
  }
}

.hover\:underline:hover {
  text-decoration-line: underline;
}
/*# sourceMappingURL=index.788d71a8.css.map */
